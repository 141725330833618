<template>
  <div
    class="d-flex justify-content-center align-items-center subscribe_layout"
    style="position: relative"
  >
    <div @click="changeLang()" class="lang-header">
      <p>{{ this.$i18n.locale.toUpperCase() }}</p>
    </div>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    changeLang() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "ar"
        localStorage.setItem("lang", "ar")
      } else {
        this.$i18n.locale = "en"
        localStorage.setItem("lang", "en")
      }
    },
  },
}
</script>

<style lang="scss">
.lang-header {
  position: absolute;
  top: 2%;
  left: 2%;
  border: 4px solid #000;
  border-radius: 8px;
  color: #000;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
}

.subscribe_layout {
  background-image: url("../assets/images/desktop_background.png");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // background-size: 100% 100%;

  @media (max-width: 768px) {
    background-image: url("../assets/images/mobile_background.png");
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 500px;
    font-size: 40.621px;
    line-height: 49px;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 22.621px;
    line-height: 22px;
  }

  .text_info {
    color: white;

    @media (min-width: 768px) {
      font-size: 44px;
    }

    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  .input,
  .butn {
    width: inherit;
  }

  .game_input,
  .game_btn {
    width: 100%;
    // background: linear-gradient(90deg, #586762 0%, #2C3C3B 100%);
    background: url("../assets/images/textfield_background.png") no-repeat
      center;
    background-size: contain;
    opacity: 0.8;
    outline: none !important;
    border: none !important;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    color: #ffffff;

    @media (min-width: 768px) {
      height: 81px;
    }

    @media (max-width: 768px) {
      height: 55px;
    }
  }

  .activeBtn {
    background: url("../assets/images/button_active.png") no-repeat center;
    background-size: contain;
    // background: #E4C275 !important;x
    color: #2c3c3b;
    // font-weight: bold;
  }

  .notActiveBtn {
    background: url("../assets/images/textfield_background.png") no-repeat
      center;
    background-size: contain;
    color: #ffffff;
  }

  .game_input {
    @media (min-width: 768px) {
      padding-left: 60px;
    }

    @media (max-width: 768px) {
      padding-left: 40px;
    }
  }

  .game_btn {
    outline: none;
    padding: 16px !important;
  }

  #otp {
    display: flex;
    justify-content: space-around;
    width: inherit;
    margin-bottom: 2rem;

    input {
      height: 70px;
      width: 70px;
      text-align: center;
      background: linear-gradient(90deg, #586762 0%, #2c3c3b 100%);
      opacity: 0.9;
      font-size: 30px;
      outline: none;
      border-radius: 8px;
      // border: 0.2px solid #E018E3;
      color: white;

      @media (max-width: 768px) {
        height: 60px;
        width: 20%;
      }
    }
  }

  .desc {
    width: 100% !important;
    text-align: center;
    position: relative !important;
    top: 25px !important;

    @media (min-width: 768px) {
      font-size: 13px;
    }

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
}

::placeholder {
  color: white;
  text-align-last: center !important;
}

.desktop_view {
  .game_input,
  .game_btn {
    width: 100%;
    // background: linear-gradient(90deg, #586762 0%, #2C3C3B 100%);
    background: url("../assets/images/textfield_background.png") no-repeat
      center;
    background-size: contain;
    opacity: 0.8;
    outline: none !important;
    border: none !important;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    color: #ffffff;

    @media (min-width: 768px) {
      height: 50px;
    }
  }

  .activeBtn {
    background: url("../assets/images/button_active.png") no-repeat center;
    background-size: contain;
    // background: #E4C275 !important;x
    color: #bca467;
  }

  .notActiveBtn {
    background: url("../assets/images/textfield_background.png") no-repeat
      center;
    background-size: contain;
    color: #ffffff;
  }

  .game_input {
    @media (min-width: 768px) {
      padding-left: 20px;
    }
  }

  .game_btn {
    outline: none;
    padding: 16px !important;
  }

  #otp {
    display: flex;
    justify-content: space-around;
    width: inherit;
    margin-bottom: 2rem;

    input {
      height: 70px;
      width: 70px;
      text-align: center;
      background: linear-gradient(90deg, #586762 0%, #2c3c3b 100%);
      opacity: 0.9;
      font-size: 30px;
      outline: none;
      border-radius: 8px;
      // border: 0.2px solid #E018E3;
      margin-right: 10px;
      color: white;

      @media (max-width: 768px) {
        height: 60px;
        width: 20%;
      }
    }
  }
}
</style>
